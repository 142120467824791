.slider {
  position: relative;
  /* width: 100%; */
  /* max-width: 600px; */
  margin: 0 auto;
}

.slideContainer {
  display: flex;
  overflow: hidden;
}

.slide {
  display: none;
}

.header {
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

.header span {
  border-bottom: 0.3rem solid #cc1f26;
}

.slide.active {
  display: block;
}

.image {
  width: 390px;
  height: 300px;
  margin: 1rem;
  padding: 0.5rem;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.prev {
  left: 0;
  z-index: 999;
}

.next {
  right: 0;
  z-index: 999;
}

@media screen and (max-width: 450px) {
  .image {
    width: 195px;
    height: 150px;
    margin: 0.5rem;
    padding: 0.1rem;
  }
}
