.container {
  padding: 1rem 2rem;
}

.container h2 {
  /* text-align: center; */
  /* margin: 1rem 0; */
  /* color: #4c4c4c; */
}

.container h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 500; */
}

.lineHeight p {
  margin: 1.3rem 0;
}

.container p {
  text-rendering: optimizeLegibility;
  line-height: 28px;
  /* letter-spacing: 1px; */
  color: #4c4c4c;
  font-size: 15px;
  margin-bottom: 0.7rem 0;
}

.readMoreBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.readMoreBtn:hover {
  background-color: #0056b3;
}

.background {
  background-color: #eeeeee;
  padding: 1rem;
  margin: 2rem 0;
}

.infraStructureContent {
  padding: 0 3rem;
}

.infraStructureContent p {
  text-rendering: optimizeLegibility;
  /* letter-spacing: 1px; */
  /* line-height: 27px;
  /* color: #4c4c4c; */
  /* font-size: 15px; */
  /* margin-bottom: 0.5rem; */ 
}

.infraStructureContent h2 {
  /* text-align: center; */
  text-transform: uppercase;
  margin: 1.5rem 0;
  color: #4c4c4c;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}

/*----------------------------------------------------------------------------*/
.heading {
  /* background-color: #deebff; */
  /* text-align: center;
  color: #4c4c4c; */
  /* padding: 0.4rem; */
}

.textAlign {
  text-align: center;
  text-transform: uppercase;
}

.infraImg {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 1rem;
}

/* Image inside the box */
.box3 img {
  height: 250px;
  width: 250px;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  z-index: 1;
  /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

.content3 h3 {
  font-size: 21px;
  font-weight: 200;
}

/* Pseudo-element for background color */
.box3::before {
  /* content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Initially covers half of the box */
  /* height: 20%; */
  /* Initial semi-transparent background color */
  /* background: rgba(51, 51, 51, 0.65); */
  /* Ensure it is behind the content */
  /* z-index: 0; */
  /* Smooth transition for height change */
  /* transition: height 0.5s ease-in-out; */ 
}

/* load slow */
.slow-load {
  animation-name: slowload;
  animation-iteration-count: 1;
  animation-duration: 200ms;
}

@keyframes slowload {
  0% {
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}




/* Hover state for the pseudo-element */
.box3:hover::before {
  height: 60%;
  /* Cover the full box on hover */
  transition: height 0.5s ease-in-out;
  /* Smooth transition for height change */
}

.box3:hover .content3 h3 {
  transform: translateY(-550%);
}

.box3 .content3 h3 {
  /* Smooth transition for the transform property */
  transition: transform 0.5s ease-in-out;
  font-size: 12px;
}

/* .backgroundDark {
  background-color: rgba(0, 0, 0, 0.332);
} */

.btnRead {
  display: flex;
  justify-content: end;
}

.enquiryNotice {
  margin: 2rem 6rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

@media screen and (max-width: 1170px) {
  .enquiryNotice {
    margin: 0 2rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 1025px) {
  .enquiryNotice {
    flex-direction: column;
  }
}

@media screen and (max-width: 695px) {
  .infraStructureContent {
    padding: 0;
  }
}

@media screen and (max-width: 445px) {
  .container h2 span {
    font-size: 19px;
  }

  .infraStructureContent h2 {
    margin: 1rem 0;
    font-size: 19px;
  }

  .home {
    padding: 0.5rem 1rem;
  }
}