.footer {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.155);
  color: #4c4c4c;
}

.footerContent {
  justify-content: space-around;
  display: flex;
  padding: 1rem;
}

.logo {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  position: relative;
  right: 80px;
}

.logo img {
  height: 100px;
  width: auto;
}

.copyright {
  color: #fff;
  background-color: black;
  padding: 0.9rem;
  text-align: center;
  justify-content: center;
  border-top: 1px solid #333;
  /* letter-spacing: 1.5px; */
}

.logoLink img {
  height: 25px;
  margin: 0 0.5rem;
}

.flex {
  display: flex;
  flex-direction: column;
  margin: 0.3rem 0.7rem;
}

.flexC {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.flexRow {
  display: flex;
}

.flexC h3 {
  font-family: sans-serif;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.5rem;
  /* letter-spacing: 1px; */
}

.flexC p {
  /* letter-spacing: 1px; */
  line-height: 1.5rem;
  font-size: 15px;
}

.flexC span {
  /* margin-bottom: 0.3rem; */
  /* letter-spacing: 1px; */
  font-size: 15px;
  line-height: 1.5rem;
}

.quickLink {
  color: #4c4c4c;
  text-decoration: none;
  /* margin-bottom: 0.3rem; */
  /* letter-spacing: 1px; */
  font-size: 15px;
  line-height: 1.5rem;
}

.quickLink span {
  line-height: 2.5rem;
}

.quickLink span:hover {
  border-bottom: 1px solid #4c4c4c;
}

.space {
  margin-top: 0.4rem;
}

.space1 {
  margin-top: 4rem;
  text-align: center;
}

.location {
  color: #4c4c4c;
  font-weight: 500;
  text-decoration: none;
}

.location span img {
  height: 1.2rem;
}

.googleMap {
  margin-top: 1rem;
}

.googleMapIcon {
  margin-bottom: 0.1rem;
}


.callIcon {
  height: 1rem;
}

.fontW {
  font-weight: 300;
}

@media screen and (max-width: 1180px) {
  .footerContent {
    display: inline;
  }

  .flexC {
    margin: 1rem;
  }

  .center {
    text-align: center;
  }

  .logo {
    right: 10px;
  }
}