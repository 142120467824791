.clientDetail {
  text-align: center;
  margin: 1rem;
}

.clientDetail h2 {
  margin: 0.5rem;
  /* color: #c0c7d0; */
  color: #333;
}

.clientDetail ul li {
  list-style: none;
  color: #fff;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #e1e8ef;
  padding: 2rem;
  /* align-items: center; */
}

.infraStructureContent {
  padding: 0 3rem;
}

.infraStructureContent h2 {
  /* text-align: center; */
  text-transform: uppercase;
  margin: 1rem 0;
  color: #4c4c4c;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}

/*----------------------------------------------------------------------------*/

.textAlign {
  text-align: center;
  text-transform: uppercase;
}

.infraImg {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 1rem;
}

/* Image inside the box */
.box3 img {
  background-color: #fff;
  height: 200px;
  width: 200px;
  padding: 0.2rem;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  z-index: 1;
  /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

/* Pseudo-element for background color */
.box3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Initially covers half of the box */
  height: 24%;
  /* Initial semi-transparent background color */
  background: rgba(51, 51, 51, 0.65);
  /* Ensure it is behind the content */
  z-index: 0;
  /* Smooth transition for height change */
  transition: height 0.5s ease-in-out;
}

/* Hover state for the pseudo-element */
.box3:hover::before {
  height: 60%;
  /* Cover the full box on hover */
  transition: height 0.5s ease-in-out;
  /* Smooth transition for height change */
}

.box3:hover .content3 h3 {
  transform: translateY(-550%);
}

.box3 .content3 h3 {
  /* Smooth transition for the transform property */
  transition: transform 0.5s ease-in-out;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width: 550px) {
  .infraStructureContent {
    padding: 0.5rem;
  }

  .box3 img {
    height: 100px;
    width: 100px;
  }

  .box3 .content3 h3 {
    font-size: 5px;
  }

  .content3 {
    padding: 0.5rem;
  }
}



