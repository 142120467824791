nav {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  top: 83px;
  z-index: 20;
  background: white;
  font-family: "Poppins", sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.logo {

  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: white;
  display: flex;
  align-content: center;
  justify-content: center;
}

.navbarLink {
  text-decoration: none;
}

.logoImg {
  height: 65px;
  width: 100%;


}

.inputSearch input {
  width: 20rem;
  border-radius: 1rem;
  padding: 0.4rem 2rem;
  outline: none;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.inputSearch:hover input {
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.75);
}

.inputSearch img {
  padding-left: 0.2rem;
  border-left: 1px solid black;
  height: 1rem;
  position: relative;
  top: 4px;
  right: 25px;
  cursor: pointer;
}

.navbarLink li {
  list-style: none;
  font-size: 1rem;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 200ms ease-in-out;
  border-bottom: 2px solid transparent;
}

.navbarLink li:hover {
  /* border-bottom: 2px solid rgb(0, 0, 0); */
  transition: all 200ms ease-in-out;
  /* color: red; */
}

.searchLogo {
  height: 25px;
}

/*
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  padding: 12px 16px;
  z-index: 1;
}

.dropdownContent input {
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0.1rem;
  outline: none;
}

.dropdown:hover .dropdownContent {
  display: block;
}
*/

.dropdown {
  position: relative;
  display: inline-block;
}

.searchLogo {
  cursor: pointer;
}

.dropdownContent {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  /* background-color: #f9f9f9; */
  padding: 0 16px;
  /* No padding initially */
  z-index: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    padding 0.3s ease-in-out;
  /* Smooth transition */
  right: 0;
  /* Align to the right */
}

.dropdownContent input {
  width: 14rem;
  padding: 8px;
  box-sizing: border-box;
  right: 0.1rem;
  outline: none;
}

.dropdown:hover .dropdownContent {
  max-height: 100px;
  /* Adjust based on the content height */
  opacity: 1;
  /* Make visible */
  padding: 12px 16px;
  /* Add padding */
}

.menuBtn {
  cursor: pointer;
  display: none;
}

.menuBtn li {
  list-style: none;
}

.menuBtn li img {
  height: 1.5rem;
  /* margin: 1rem; */
}

/* .menuContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  transition: all 0.5s ease-in-out;
} */

/* .menuContainer {
  position: sticky;
  top: 6rem;
  left: 37rem;
  width: 40%;
  height: 90%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
  transition: all 0.5s ease-in-out;
} */

img {
  height: 65px;


}

.menuContainer.show {
  display: block;
}

@media screen and (max-width: 650px) {
  img {
    height: 50px;
  }
}